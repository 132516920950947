import React, { useRef, useState } from "react";
import PlayButton from "images/play-button.inline.svg";
import IconButton from "components/icon-button";
import PauseIcon from "images/pause-icon.inline.svg";
import "./SlidePlayButton.scss";

const CarouselVideoSlide = React.memo(
    ({ thumbnailSrc, src, className, style, onClick }) => {
        const props = {};
        if (onClick) {
            props.onClick = onClick;
        }
        const videoRef = useRef(null);
        const [playing, setPlaying] = useState(false);

        const toggleVideo = () => {
            const videoElement = videoRef.current;

            if (videoElement.paused) {
                setPlaying(true);
                videoElement.play();
            } else {
                setPlaying(false);
                videoElement.pause();
            }
        };

        return (
            <div className={className} style={style} {...props}>
                <video poster={thumbnailSrc} ref={videoRef} playsInline>
                    <source src={src} type="video/mp4" />
                </video>
                {!playing ? (
                    <IconButton
                        Icon={PlayButton}
                        type="button"
                        onClick={toggleVideo}
                        className="slide-play-btn"
                    />
                ) : (
                    <IconButton
                        Icon={PauseIcon}
                        type="button"
                        onClick={toggleVideo}
                        className="slide-play-btn"
                    />
                )}
            </div>
        );
    }
);

export default CarouselVideoSlide;
