import React from "react";
import CarouselVideoSlide from "components/carousel/CarouselVideoSlide";
import CarouselSlide from "components/carousel/CarouselSlide";
import CarouselCloudinaryVideo from "components/carousel/CarouselCloudinaryVideo";
import Carousel from "re-carousel";
import IndicatorDots from "./IndicatorDots";
import "./MobileCarousel.scss";
import { useRef, useState } from "react";
import { motion } from "framer-motion";

const MobileCarousel = React.memo(({ slides }) => {
    const carouselRef = useRef(null);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

    const carouselConfig = {
        widgets: [IndicatorDots],
        className: "slider-wrapper",
        onTransitionEnd: ({ current }) => {
            const allSlides =
                carouselRef.current.children[0].querySelector(
                    ".slider-wrapper"
                ).children;

            for (let index = 0; index < allSlides.length; index++) {
                const element = allSlides[index];
                if (element === current) {
                    setCurrentSlideIndex(index);
                    break;
                }
            }
        },
    };

    const contentTransition = {
        exit: {
            opacity: 0,
        },

        enter: {
            opacity: 1,
            transition: {
                type: "tween",
                ease: "easeIn",
                duration: 1,
            },
        },
    };

    return (
        <div
            ref={carouselRef}
            className="mobile-carousel"
            key={`mobile-carousel-${slides.length}`}
        >
            <Carousel {...carouselConfig}>
                {slides.map((slide, key) => {
                    let slideComponent = null;

                    if (slide.image) {
                        slideComponent = (
                            <CarouselSlide
                                key={key}
                                url={slide.image.fixed.src}
                                alt={slide.image.alt}
                            />
                        );
                    } else if (slide.video) {
                        slideComponent = (
                            <CarouselVideoSlide
                                key={key}
                                thumbnailSrc={slide.video.video.thumbnailUrl}
                                src={slide.video.video.mp4Url}
                            />
                        );
                    } else if (slide.cloudinaryId) {
                        slideComponent = (
                            <CarouselCloudinaryVideo
                                key={key}
                                cloudinaryId={slide.cloudinaryId}
                            />
                        );
                    }

                    return slideComponent;
                })}
            </Carousel>
            <div className="caption-container">
                {slides.map((slide, key) => {
                    return currentSlideIndex === key ? (
                        <motion.div
                            initial="exit"
                            exit="exit"
                            animate="enter"
                            variants={contentTransition}
                            key={key}
                        >
                            {slide.image && slide.image.title}
                            {slide.video && slide.video.title}
                        </motion.div>
                    ) : null;
                })}
            </div>
        </div>
    );
});

export default MobileCarousel;
