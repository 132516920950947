import React from "react";

const CarouselSlide = React.memo(
    ({ className, alt, style, url, onClick, title }) => {
        const props = {};
        if (onClick) {
            props.onClick = onClick;
        }
        return (
            <div style={style} className={className} {...props}>
                <img src={url} alt={alt} />
                <p className="caption">{title}</p>
            </div>
        );
    }
);

export default CarouselSlide;
