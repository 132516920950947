import React, { useRef, useState } from "react";
import IconButton from "components/icon-button";
import ReactPlayer from "react-player";
import FullscreenSvg from "images/fullscreen.inline.svg";
import "./SlideFullscreenButton.scss";
import ProgressBar from "components/progress-bar";

const CarouselCloudinaryVideo = React.memo(
    ({ cloudinaryId, className, style, onClick, caption }) => {
        const props = {};
        if (onClick) {
            props.onClick = (e) => onClick(e);
        }
        const videoRef = useRef();
        const [playing, setPlaying] = useState(false);
        const [playedFirstTime, setPlayedFirstTime] = useState(false);
        const [played, setPlayed] = useState(0);
        const [duration, setDuration] = useState(0);
        const [seeking, setSeeking] = useState(false);
        const thumbnailSrc = `https://media.earthspeakr.art/video/upload/so_1/videos/${cloudinaryId}.jpg`;

        const handleProgress = (state) => {
            // We only want to update time slider if we are not currently seeking
            if (!seeking) {
                setPlayed(state.played);
            }
        };

        const handleDuration = (duration) => {
            setDuration(duration);
        };

        const onSliderChange = (value) => {
            setPlayed(value / 100);
            videoRef.current.seekTo(parseFloat(value / 100));
        };

        const togglePlaystate = () => {
            setPlaying(!playing);
            if (!playedFirstTime) {
                setPlayedFirstTime(true);
            }
        };

        const videoProps = {
            playing: playing,
            playsinline: true,
            className: "react-player",
            url: `https://media.earthspeakr.art/video/upload/sp_full_hd/videos/${cloudinaryId}.m3u8`,
            controls: false,
            volume: 1,
            loop: false,
            muted: false,
            width: "100%",
            height: "100%",
            onProgress: handleProgress,
            onDuration: handleDuration,
            config: {
                attributes: {
                    poster: thumbnailSrc,
                },
            },
            onPause: () => {
                setPlaying(false);
            },
        };

        const onBeforeChange = () => {
            setSeeking(true);
        };

        const onAfterChange = () => {
            setSeeking(false);
        };

        const setFullScreen = () => {
            const video = videoRef.current.wrapper.querySelector("video");

            if (video.requestFullscreen) {
                video.requestFullscreen();
            } else if (video.mozRequestFullScreen) {
                /* Firefox */
                video.mozRequestFullScreen();
            } else if (video.webkitRequestFullscreen) {
                /* Chrome, Safari & Opera */
                video.webkitRequestFullscreen();
            } else if (video.msRequestFullscreen) {
                /* IE/Edge */
                video.msRequestFullscreen();
            } else {
                video.webkitSetPresentationMode("fullscreen");
            }
        };

        return (
            <div className={className} style={style} {...props}>
                <ReactPlayer {...videoProps} ref={videoRef} />
                {!playedFirstTime && (
                    <img
                        className="desktop-carousel__slide-poster"
                        src={thumbnailSrc}
                        alt="earth-speakr poster"
                    />
                )}
                <ProgressBar
                    played={played}
                    onSliderChange={onSliderChange}
                    duration={duration}
                    playing={playing}
                    togglePlaystate={togglePlaystate}
                    onBeforeChange={onBeforeChange}
                    onAfterChange={onAfterChange}
                />
                <IconButton
                    Icon={FullscreenSvg}
                    className="slide-fullscreen-btn"
                    onClick={setFullScreen}
                />
                {caption && <p className="caption">{caption}</p>}
            </div>
        );
    }
);

export default CarouselCloudinaryVideo;
